import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ComponentProviderProps } from '@business/interfaces/component-provider';
import { routePaths } from '@utils/constants/routePaths';
import { getToken } from '@ports/common/sessionStateSlice';
import { useLocation, useNavigate } from 'react-router-dom';

/** TO DO Implement logic for handling authUrls */

const authUrls = [
  '/',
  routePaths.CreateAccount,
  routePaths.SignIn,
  routePaths.ForgotPassword,
  routePaths.ResetPassword,
  routePaths.CreateUser,
  routePaths.AreaCreation,
  routePaths.CreateCountry,
  routePaths.CountryDetails,
  routePaths.SignUpComplete,
  routePaths.SignUp,
  routePaths.CountryList,
  routePaths.AuthFlow,
  routePaths.CreateMealRequest
];

/*
 * This component is used redirect the user to corresponding page if user is logged in
 */
export function AuthGuard({ children }: Readonly<ComponentProviderProps>) {
  const navigate = useNavigate();
  const location = useLocation();
  const storeToken = useSelector(getToken);
  const sessionToken = sessionStorage.getItem('token');
  const token = storeToken ? storeToken : sessionToken;

  useEffect(() => {
    if (!token && !authUrls.includes(location.pathname)) {
      navigate(routePaths.SignIn);
    } else if (
      token &&
      [
        routePaths.CreateAccount,
        routePaths.SignIn,
        routePaths.ForgotPassword,
        routePaths.ResetPassword,
        '/'
      ].includes(location.pathname)
    ) {
      navigate(routePaths.Dashboard);
    }
  }, [location.pathname, token]);

  return <>{children}</>;
}
