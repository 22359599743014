import { downloadCenterApi as api } from './downloadCenterApi';
export const addTagTypes = ['account_details'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      accountDetailsDownloadCentreList: build.query<
        AccountDetailsDownloadCentreListApiResponse,
        AccountDetailsDownloadCentreListApiArg
      >({
        query: queryArg => ({
          url: `/account_details/download_centre/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['account_details']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };
export type AccountDetailsDownloadCentreListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: DownloadCentreRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type AccountDetailsDownloadCentreListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type DownloadCentre = {
  name: string;
  url?: string | null;
  created_by?: string;
  file_type?: string | null;
  model_type?: string | null;
  fields?: string | null;
  filters?: object | null;
};
export type DownloadCentreRead = {
  name: string;
  url?: string | null;
  created_by?: string;
  created_at?: string;
  file_type?: string | null;
  status?: 'inprogress' | 'downloadable';
  model_type?: string | null;
  fields?: string | null;
  filters?: object | null;
};
export const { useAccountDetailsDownloadCentreListQuery } = injectedRtkApi;
