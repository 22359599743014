import React, { useState, useMemo, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { signOut } from 'aws-amplify/auth';
import { useDispatch, useSelector } from 'react-redux';
import { SVGAssets } from '@business/assets/vectors';
import { routePaths } from '@utils/constants/routePaths';
import { setToken } from '@ports/common/sessionStateSlice';
import { setIsManualLoading } from '@ports/common/manualLoadingStateSlice';
import { useNavigate } from 'react-router-dom';
import { SidebarProps } from '@business/interfaces/sidebar';
import { Button } from '../Button';
import { getUserData } from '@ports/common/userStateSlice';
import {
  getMenuItems,
  getResourceType,
  getTopMenuClicked,
  setMenuItems,
  setResource,
  setResourceType
} from '@ports/common/sideBarStateSlice';
import { useAccountDetailsTutorialListQuery } from '@ports/internal-services/user/userSlice';
import { menuItem } from '@utils/constants/globals';

export const Sidebar: React.FC<SidebarProps> = ({ isAdmin }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector(getUserData);
  const menuItems: any = useSelector(getMenuItems);
  const topMenu = useSelector(getTopMenuClicked);
  const recourseType = useSelector(getResourceType);
  const usePermissions = useMemo(() => {
    return (
      userDetails?.results?.permissions?.map(
        permission => permission?.codename
      ) || []
    );
  }, [userDetails]);
  const { data: accountData, refetch } = useAccountDetailsTutorialListQuery({
    groupAccessName: recourseType
  });

  useEffect(() => {
    refetch();
    if (menuItem?.length > 0) {
      const updatedMenuItems = menuItem?.map((item: any) => ({
        ...item,
        selected:
          item?.child?.length > 0 && topMenu !== 'view_resources'
            ? item?.child?.some(
                (childItem: any) => childItem.href === window.location.pathname
              )
            : item?.urls?.includes(window.location.pathname),
        child: item?.child?.map((childItem: any) => ({
          ...childItem,
          selected: childItem.href === window.location.pathname
        }))
      }));

      dispatch(setMenuItems(updatedMenuItems));
    }
  }, []);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  useEffect(() => {
    refetch();
  }, [recourseType]);

  const handleItemClick = async (label: string) => {
    let updatedMenuItems: any[];
    if (label === 'sidebar.signOut') {
      dispatch(
        setIsManualLoading({
          isLoading: true
        })
      );
      await signOut();
      dispatch(setToken({ token: null }));
      localStorage.clear();
      sessionStorage.clear();
      navigate(routePaths.SignIn);
      dispatch(
        setIsManualLoading({
          isLoading: false
        })
      );
    } else if (label === 'sidebar.zip') {
      updatedMenuItems = menuItems?.map((item: any) => ({
        ...item,
        selected: item.label === label,
        child: item?.child?.map((childItem: any) => ({
          ...childItem,
          selected: childItem.label === 'zipCode.allocated'
        }))
      }));
      navigate(routePaths.ListZipCode);
    } else if (label === 'sidebar.introToLasagna') {
      dispatch(setResourceType('intro'));
      updatedMenuItems = menuItems?.map((item: any) => ({
        ...item,
        selected: item.label === label,
        child: item?.child?.map((childItem: any, index: number) => ({
          ...childItem,
          selected: index === 0
        }))
      }));
    } else if (label === 'sidebar.volunteerTraining') {
      dispatch(setResourceType('volunteer'));
      updatedMenuItems = menuItems?.map((item: any) => ({
        ...item,
        selected: item.label === label,
        child: item?.child?.map((childItem: any, index: number) => ({
          ...childItem,
          selected: index === 0
        }))
      }));
    } else {
      updatedMenuItems = menuItems?.map((item: any) => ({
        ...item,
        selected: item.label === label,
        child: item?.child?.map((childItem: any) => ({
          ...childItem,
          selected: childItem.label === label
        }))
      }));
    }
    dispatch(setMenuItems(updatedMenuItems));
  };
  const resourceChange = (tempIndex: number, childData: any) => {
    if (tempIndex !== -1) {
      const tempArray = menuItem?.map((item: any, index: number) =>
        index === tempIndex
          ? { ...item, child: childData, selected: true }
          : { ...item, selected: false }
      );
      const selectedItem = childData?.find((item: any) => item.selected);
      const selectedItemIndex = childData?.findIndex(
        (item: any) => item.selected
      );
      if (accountData?.results?.length > 0) {
        const selectedResource = accountData?.results?.find(
          item => item?.name === selectedItem?.label
        );
        if (selectedResource) {
          dispatch(
            setResource({
              type: selectedResource?.file?.content_type,
              url: selectedResource?.file?.url,
              title: selectedResource?.name,
              index: selectedItemIndex + 1,
              uuid: selectedResource?.uuid,
              resource: recourseType
            })
          );
        }
      }

      dispatch(setMenuItems(tempArray));
    }
  };
  useEffect(() => {
    if (
      accountData?.results?.length > 0 &&
      topMenu === 'view_resources' &&
      window.location.pathname !== '/faq'
    ) {
      const tempIndex = menuItem?.findIndex(
        (item: any) =>
          item.label ===
          (recourseType === 'intro'
            ? 'sidebar.introToLasagna'
            : 'sidebar.volunteerTraining')
      );
      const childItem = menuItems?.[tempIndex]?.child?.find(
        (item: any) => item?.selected
      );
      const childData = accountData?.results?.map(
        (item: any, index: number) => ({
          no: index + 40,
          label: item?.name,
          href: routePaths.Resources,
          selected: childItem ? childItem?.label === item?.name : index === 0,
          permission: 'view_resources'
        })
      );
      if (recourseType === 'intro') {
        resourceChange(tempIndex, childData);
      } else if (recourseType === 'volunteer') {
        resourceChange(tempIndex, childData);
      }
    }
  }, [accountData, recourseType]);

  function sideNavMenu() {
    const element2 = document.getElementById('sideNav');
    const element3 = document.getElementById('sideNavMenu');
    element2.classList.toggle('sideNav-toggle');
    element3.classList.toggle('rotate-img');
    setIsVisible(!isVisible);
  }

  return (
    <div className="w-full lg:w-2/12 mb-3 lg:mb-0">
      <div className="bg-white rounded-lg w-full h-full lg:w-11/12">
        <div className="flex justify-between items-center border-b-primary">
          <h4 className="secondary-font color-secondary-hover p-4">
            {topMenu === 'view_resources'
              ? t('header.resources')
              : t('sidebar.title')}
          </h4>
          <SVGAssets.Expand
            className="w-5 mr-5 lg:hidden cursor-pointer"
            id="sideNavMenu"
            data-testid="sideNavMenu"
            onClick={sideNavMenu}
          />
        </div>
        <ul
          className={`nav hidden lg:block ${isVisible ? 'sideNav-toggle' : ''}`}
          id="sideNav"
          data-testid="sideNav">
          {menuItems
            ?.filter((item: any) => {
              if (
                ((topMenu == null ||
                  topMenu === '' ||
                  topMenu !== 'view_resources') &&
                  !item?.type) ||
                item?.permission === 'signOut'
              ) {
                return true;
              }
              return item?.type === topMenu;
            })
            ?.map((item: any, i: number) => (
              <>
                {item.permission &&
                  (usePermissions.includes(item.permission) ||
                    (item?.permission === 'signOut' &&
                      topMenu !== 'view_resources')) && (
                    <Fragment key={i + 1}>
                      <li
                        key={item.label}
                        role="none"
                        className={`${item.selected ? 'active font-bold' : 'font-bold text-[var(--na-lite-gray4)]'} flex`}>
                        <Button
                          btnStyle="link"
                          className={`w-full text-left `}
                          onClick={() => handleItemClick(item.label)}
                          href={item.href}>
                          {t(item.label)}
                        </Button>
                        {(item?.child?.length > 0 ||
                          item?.permission === 'view_resources') && (
                          <>
                            {menuItems?.some(
                              (items: any) =>
                                items.label === item?.label && items.selected
                            ) ? (
                              <SVGAssets.ArrowOpen className="w-4 h-4" />
                            ) : (
                              <SVGAssets.ArrowClose className="w-4 h-4" />
                            )}
                          </>
                        )}
                      </li>
                      {item?.selected && item?.child?.length > 0 && (
                        <div className="max-h-56 overflow-y-auto">
                          {item?.child?.map((items: any, index: number) => (
                            <div
                              className={`${items.selected ? 'active font-bold' : 'font-bold text-[var(--na-lite-gray4)]'} text-sm pl-4`}
                              key={index + 1}>
                              <li
                                role="none"
                                className="cursor-pointer"
                                onClick={() => {
                                  const updatedMenuItems = menuItems.map(
                                    (menuItemData: any) =>
                                      menuItemData?.label === item.label
                                        ? {
                                            ...menuItemData,

                                            child: menuItemData?.child?.map(
                                              (itemData: any) => ({
                                                ...itemData,
                                                selected:
                                                  itemData.label ===
                                                  items?.label
                                              })
                                            )
                                          }
                                        : menuItemData
                                  );
                                  if (accountData?.results?.length > 0) {
                                    const childItem =
                                      accountData?.results?.find(
                                        (childItem: any) =>
                                          childItem?.name === items?.label
                                      );
                                    if (childItem) {
                                      dispatch(
                                        setResource({
                                          type: childItem?.file?.content_type,
                                          url: childItem?.file?.url,
                                          title: childItem?.name,
                                          index: index + 1,
                                          uuid: childItem?.uuid,
                                          resource: recourseType
                                        })
                                      );
                                    }
                                  }
                                  dispatch(setMenuItems(updatedMenuItems));
                                  navigate(items.href);
                                }}>
                                {t(items?.label)}
                              </li>
                            </div>
                          ))}
                        </div>
                      )}
                    </Fragment>
                  )}
              </>
            ))}
        </ul>
      </div>
    </div>
  );
};
