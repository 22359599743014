import { userApi as api } from './userApi';
export const addTagTypes = ['account_details', 'common', 'user'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      accountDetailsTutorialList: build.query<
        AccountDetailsTutorialListApiResponse,
        AccountDetailsTutorialListApiArg
      >({
        query: queryArg => ({
          url: `/account_details/tutorial/`,
          params: {
            group_access__name: queryArg.groupAccessName,
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['account_details']
      }),
      accountDetailsTutorialUnwatchedTutorial: build.query<
        AccountDetailsTutorialUnwatchedTutorialApiResponse,
        AccountDetailsTutorialUnwatchedTutorialApiArg
      >({
        query: queryArg => ({
          url: `/account_details/tutorial/unwatched_tutorial/`,
          params: {
            group_access__name: queryArg.groupAccessName,
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            file__content_type: queryArg.fileContentType
          }
        }),
        providesTags: ['account_details']
      }),
      accountDetailsTutorialWatchedTutorial: build.mutation<
        AccountDetailsTutorialWatchedTutorialApiResponse,
        AccountDetailsTutorialWatchedTutorialApiArg
      >({
        query: queryArg => ({
          url: `/account_details/tutorial/watched_tutorial/`,
          method: 'POST',
          body: queryArg.watchTutorial
        }),
        invalidatesTags: ['account_details']
      }),
      commonCredentialsList: build.query<
        CommonCredentialsListApiResponse,
        CommonCredentialsListApiArg
      >({
        query: () => ({ url: `/common/credentials/` }),
        providesTags: ['common']
      }),
      userCallbackCreate: build.mutation<
        UserCallbackCreateApiResponse,
        UserCallbackCreateApiArg
      >({
        query: queryArg => ({
          url: `/user/callback/`,
          method: 'POST',
          body: queryArg.body
        }),
        invalidatesTags: ['user']
      }),
      userNotifiedfromList: build.query<
        UserNotifiedfromListApiResponse,
        UserNotifiedfromListApiArg
      >({
        query: queryArg => ({
          url: `/user/notifiedfrom/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['user']
      }),

      userPermissionsList: build.query<
        UserPermissionsListApiResponse,
        UserPermissionsListApiArg
      >({
        query: queryArg => ({
          url: `/user/permissions/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['user']
      }),
      userSignupflowRead: build.query<
        UserSignupflowReadApiResponse,
        UserSignupflowReadApiArg
      >({
        query: queryArg => ({ url: `/user/signupflow/${queryArg.uuid}/` }),
        providesTags: ['user']
      }),
      userSignupflowPartialUpdate: build.mutation<
        UserSignupflowPartialUpdateApiResponse,
        UserSignupflowPartialUpdateApiArg
      >({
        query: queryArg => ({
          url: `/user/signupflow/${queryArg.uuid}/`,
          method: 'PATCH',
          body: queryArg.signupFlow
        }),
        invalidatesTags: ['user']
      }),
      userUserRegionDetailList: build.query<
        UserUserRegionDetailListApiResponse,
        UserUserRegionDetailListApiArg
      >({
        query: queryArg => ({
          url: `/user/user_region_detail/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['user']
      }),
      userUserinfoList: build.query<
        UserUserinfoListApiResponse,
        UserUserinfoListApiArg
      >({
        query: queryArg => ({
          url: `/user/userinfo/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['user']
      }),
      userUsersRead: build.query<UserUsersReadApiResponse, UserUsersReadApiArg>(
        {
          query: queryArg => ({ url: `/user/users/${queryArg.uuid}/` }),
          providesTags: ['user']
        }
      ),
      userUsersPartialUpdate: build.mutation<
        UserUsersPartialUpdateApiResponse,
        UserUsersPartialUpdateApiArg
      >({
        query: queryArg => ({
          url: `/user/users/${queryArg.uuid}/`,
          method: 'PATCH',
          body: queryArg.user
        }),
        invalidatesTags: ['user']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };
export type AccountDetailsTutorialListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: TutorialRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type AccountDetailsTutorialListApiArg = {
  /** group_access__name */
  groupAccessName?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AccountDetailsTutorialUnwatchedTutorialApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: TutorialRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type AccountDetailsTutorialUnwatchedTutorialApiArg = {
  /** Filter by group access name */
  groupAccessName?: 'intro' | 'group_names';
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Filter by file content type */
  fileContentType?: 'video' | 'pdf';
};
export type AccountDetailsTutorialWatchedTutorialApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: TutorialRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type AccountDetailsTutorialWatchedTutorialApiArg = {
  watchTutorial: WatchTutorial;
};
export type CommonCredentialsListApiResponse =
  /** status 200 Custom Response */ {
    results: {
      token?: string;
      key?: string;
      iv?: string;
    };
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type CommonCredentialsListApiArg = void;
export type UserCallbackCreateApiResponse = /** status 200 Custom Response */ {
  results: {
    uuid?: string;
    username?: string;
    email?: string;
    profile_uuid?: number;
  };
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type UserCallbackCreateApiArg = {
  body: {
    /** Username of the new user */
    username: string;
    /** User email */
    email: string;
  };
};
export type UserNotifiedfromListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: NotifiedFromRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserNotifiedfromListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type UserPermissionsListApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: PermissionRead[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type UserPermissionsListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type UserSignupflowReadApiResponse = /** status 200 Custom Response */ {
  results: SignupFlowRead;
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type UserSignupflowReadApiArg = {
  uuid: string;
};
export type UserSignupflowPartialUpdateApiResponse =
  /** status 200 Custom Response */ {
    results: SignupFlowRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserSignupflowPartialUpdateApiArg = {
  uuid: string;
  signupFlow: SignupFlow;
};
export type UserUserRegionDetailListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: UserRegionDetailRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserUserRegionDetailListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type UserUserinfoListApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: UserInfoRead;
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type UserUserinfoListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type UserUsersReadApiResponse = /** status 200 Custom Response */ {
  results: UserRead;
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type UserUsersReadApiArg = {
  uuid: string;
};
export type UserUsersPartialUpdateApiResponse =
  /** status 200 Custom Response */ {
    results: UserRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserUsersPartialUpdateApiArg = {
  uuid: string;
  user: User;
};
export type Asset = {
  url?: string | null;
  file_name?: string | null;
  size?: number | null;
  content_type?: string | null;
  asset_type?: string | null;
};
export type AssetRead = {
  uuid?: string;
  url?: string | null;
  file_name?: string | null;
  size?: number | null;
  content_type?: string | null;
  asset_type?: string | null;
};
export type Tutorial = {
  name: string;
  description: string;
  file: Asset;
  ordering?: number | null;
};
export type TutorialRead = {
  uuid?: string;
  name: string;
  description: string;
  file: AssetRead;
  ordering?: number | null;
};
export type WatchTutorial = {
  user: string;
  tutorial: string;
  watched?: boolean | null;
};
export type NotifiedFrom = {
  name: string;
};
export type NotifiedFromRead = {
  uuid?: string;
  name: string;
};
export type Permission = {
  codename: string;
};
export type PermissionRead = {
  id?: number;
  codename: string;
};
export type GroupLite = {
  name: string;
};
export type GroupLiteRead = {
  uuid?: string;
  name: string;
};
export type ProfileList = {
  gender?: ('M' | 'F' | 'PNTS') | null;
  birth_date?: string | null;
  contact_number?: string | null;
  street_address?: string | null;
  city?: string | null;
  state?: string | null;
  country_origin?: string | null;
  completed_percentage?: number;
  zip_code?: string | null;
  profile_pic?: string;
  cover_pic?: string;
  volunteering_with?: boolean;
  mailing_address_same?: number;
};
export type ProfileListRead = {
  uuid?: string;
  id?: number;
  gender?: ('M' | 'F' | 'PNTS') | null;
  birth_date?: string | null;
  over_18?: string;
  contact_number?: string | null;
  street_address?: string | null;
  city?: string | null;
  state?: string | null;
  country_origin?: string | null;
  completed_percentage?: number;
  zip_code?: string | null;
  profile_pic?: string;
  cover_pic?: string;
  volunteering_with?: boolean;
  mailing_address_same?: number;
};
export type MamaProfile = {
  cb_mamaavail?: string | null;
  cb_mamafreq?: string | null;
  cb_mamacap?: number | null;
  cb_mamafillin?: string | null;
  cb_mamaradius?: number | null;
  cb_mamaradiustype?: string | null;
  cb_mamacooks?: string | null;
  cb_mamastart?: string | null;
  cb_mamasmsok?: number | null;
  cb_mamaleader?: string | null;
  cb_mamalat?: string | null;
  cb_mamalon?: string | null;
  cb_mamatzone?: string | null;
  cb_mamamatches?: number | null;
  cb_mamadeliveries?: number | null;
  cb_hidedelivered?: string | null;
};
export type MamaProfileRead = {
  uuid?: string;
  cb_mamaavail?: string | null;
  cb_mamafreq?: string | null;
  cb_mamacap?: number | null;
  cb_mamafillin?: string | null;
  cb_mamaradius?: number | null;
  cb_mamaradiustype?: string | null;
  cb_mamacooks?: string | null;
  cb_mamastart?: string | null;
  cb_mamasmsok?: number | null;
  cb_mamaleader?: string | null;
  cb_mamalat?: string | null;
  cb_mamalon?: string | null;
  cb_mamatzone?: string | null;
  cb_mamamatches?: number | null;
  cb_mamadeliveries?: number | null;
  cb_hidedelivered?: string | null;
};
export type CommunicationPreferences = {
  latest_news_updates?: boolean;
  weekly_goodness_email?: boolean;
  text_notifications?: boolean;
};
export type OtherDetailsProfile = {
  cb_source?: string | null;
  cb_othersource?: string | null;
  cb_employer?: string | null;
  cb_organization?: string | null;
  cb_streetaddress?: string | null;
};
export type OtherDetailsProfileRead = {
  uuid?: string;
  cb_source?: string | null;
  cb_othersource?: string | null;
  cb_employer?: string | null;
  cb_organization?: string | null;
  cb_streetaddress?: string | null;
  cb_watchedvideofood?: number | null;
};
export type SignupFlow = {
  first_name?: string;
  last_name?: string;
  groups?: GroupLite;
  profile?: ProfileList;
  mamaprofile?: MamaProfile;
  communication_preferences?: CommunicationPreferences;
  other_details?: OtherDetailsProfile;
};
export type SignupFlowRead = {
  uuid?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  groups?: GroupLiteRead;
  profile: ProfileListRead;
  mamaprofile?: MamaProfileRead;
  communication_preferences?: CommunicationPreferences;
  other_details: OtherDetailsProfileRead;
};
export type UserRegionDetail = {
  zip: string;
  primary_city?: string | null;
  county?: string | null;
  area_codes?: string | null;
  state_code: string;
  district: string;
  state: string;
  country: string;
  local_leader: string;
  regional_director: string;
};
export type UserRegionDetailRead = {
  uuid?: string;
  zip: string;
  primary_city?: string | null;
  county?: string | null;
  area_codes?: string | null;
  state_code: string;
  district: string;
  state: string;
  country: string;
  local_leader: string;
  regional_director: string;
};
export type ProfileLite = {
  profile_pic: string;
  completed_percentage?: number;
  gender?: ('M' | 'F' | 'PNTS') | null;
};
export type ProfileLiteRead = {
  uuid?: string;
  profile_pic: string;
  completed_percentage?: number;
  gender?: ('M' | 'F' | 'PNTS') | null;
};
export type GroupLiteList = {
  name: string;
};
export type UserInfo = {
  email: string;
  first_name?: string;
  last_name?: string;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
  profile: ProfileLite;
  groups: GroupLiteList;
};
export type UserInfoRead = {
  uuid?: string;
  email: string;
  first_name?: string;
  last_name?: string;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
  profile: ProfileLiteRead;
  permissions?: PermissionRead[];
  groups: GroupLiteList;
};
export type MamaLiteProfileEdit = {
  cb_mamastart?: string | null;
  cb_mamasmsok?: number | null;
};
export type MamaLiteProfileEditRead = {
  uuid?: string;
  cb_mamastart?: string | null;
  cb_mamasmsok?: number | null;
};
export type User = {
  first_name?: string;
  last_name?: string;
  email: string;
  profile: ProfileList;
  groups?: GroupLite;
  other_details: OtherDetailsProfile;
  mamaprofile: MamaLiteProfileEdit;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
};
export type UserRead = {
  uuid?: string;
  id?: number;
  first_name?: string;
  last_name?: string;
  email: string;
  date_joined?: string;
  profile: ProfileListRead;
  groups?: GroupLiteRead;
  other_details: OtherDetailsProfileRead;
  mamaprofile: MamaLiteProfileEditRead;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
};
export const {
  useAccountDetailsTutorialListQuery,
  useAccountDetailsTutorialUnwatchedTutorialQuery,
  useAccountDetailsTutorialWatchedTutorialMutation,
  useCommonCredentialsListQuery,
  useUserCallbackCreateMutation,
  useUserNotifiedfromListQuery,
  useUserPermissionsListQuery,
  useUserSignupflowReadQuery,
  useUserSignupflowPartialUpdateMutation,
  useUserUserRegionDetailListQuery,
  useUserUserinfoListQuery,
  useUserUsersReadQuery,
  useUserUsersPartialUpdateMutation
} = injectedRtkApi;
