import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { enhancedApi as userApi } from '@ports/internal-services/user/userSlice';
import { enhancedApi as countryApi } from '@ports/internal-services/country/countrySlice';
import { enhancedApi as stateApi } from '@ports/internal-services/state/stateSlice';
import { enhancedApi as zipCodeApi } from '@ports/internal-services/zipCodes/zipCodeSlice';
import { enhancedApi as fileExportApi } from '@ports/internal-services/fileExport/fileExportSlice';
import { enhancedApi as scheduleApi } from '@ports/internal-services/schedule/scheduleSlice';
import { enhancedApi as localareaApi } from '@ports/internal-services/localarea/localareaSlice';
// import { enhancedApi as downaloadCenterApi } from '@ports/internal-services/downloadCenter/downaloadCenterSlice';
import { enhancedApi as resourceApi } from '@ports/internal-services/resource/resourceApiSlice';
import { enhancedApi as faqApi } from '@ports/internal-services/faq/faqSlice';
export type appLoadingStateType = {
  isLoading: boolean;
};

const initialState: appLoadingStateType = { isLoading: false };

export const appLoadingStateSlice = createSlice({
  name: 'appLoadingStateSlice',
  initialState,
  reducers: {
    setIsAppLoading: (state, action) => {
      state.isLoading = action.payload.isLoading;
    }
  },
  extraReducers: build => {
    /*
     *
     *  This function is used to intercept all API calls across the app and set the isLoading state
     *  which is used to show the loader in AppLoader.
     *
     */
    const allApis: any = {
      ...userApi?.endpoints,
      ...zipCodeApi?.endpoints,
      ...fileExportApi?.endpoints,
      ...scheduleApi?.endpoints,
      ...countryApi?.endpoints,
      ...stateApi?.endpoints,
      ...localareaApi?.endpoints,
      ...resourceApi?.endpoints,
      ...faqApi?.endpoints
    };
    const ignoreList = [
      'userUsersList',
      'regionZipcodesList',
      'regionZipcodesUploadFile',
      'regionStateUploadFile'
    ];
    const filteredApiList = Object.keys(allApis).filter(
      item => !ignoreList.includes(item)
    );
    const isAnyApiPending = isAnyOf(
      ...filteredApiList.map((item: string) => allApis[item].matchPending)
    );
    const isAnyApiFulfilledOrRejected = isAnyOf(
      ...filteredApiList.map((item: string) => allApis[item].matchFulfilled),
      ...filteredApiList.map((item: string) => allApis[item].matchRejected)
    );

    build.addMatcher(isAnyApiPending, (state, action) => {
      state.isLoading = true;
    });
    build.addMatcher(isAnyApiFulfilledOrRejected, (state, action) => {
      const isAnyStillPending = filteredApiList.some((item: string) => {
        return allApis[item].matchPending(action);
      });

      if (!isAnyStillPending) {
        state.isLoading = false;
      }
    });
  }
});

export const { setIsAppLoading } = appLoadingStateSlice.actions;
export const getAppLoadingState = (state: RootState) =>
  state.appLoadingStateSlice.isLoading;
