import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

export type manualLoadingStateType = {
  isLoading: boolean;
  isOpacityRequired?: boolean;
};

const initialState: manualLoadingStateType = {
  isLoading: false,
  isOpacityRequired: false
};

export const manualLoadingStateSlice = createSlice({
  name: 'manualLoadingStateSlice',
  initialState,
  reducers: {
    setIsManualLoading: (state, action) => {
      state.isLoading = action.payload.isLoading;
      state.isOpacityRequired = action.payload?.isOpacityRequired ?? false;
      return state;
    }
  }
});

export const { setIsManualLoading } = manualLoadingStateSlice.actions;
export const getManualLoadingState = (state: RootState) =>
  state.manualLoadingStateSlice.isLoading;
export const getOpacityRequired = (state: RootState) =>
  state.manualLoadingStateSlice.isOpacityRequired;
