import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { LocalareaProps } from '@business/interfaces/localarea';

const initialState: LocalareaProps = {
  id: null,
};

export const localareaDetailsSlice = createSlice({
  name: 'localareaDetails',
  initialState,
  reducers: {
    setAreaId: (state, action) => {
      state.id = action.payload.id;
    },
    resetAreaDetails: () => {
      return initialState;
    }
  }
});

export const { setAreaId, resetAreaDetails } = localareaDetailsSlice.actions;
export const getAreaId = (state: RootState) => state.localareaDetails;
