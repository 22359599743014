import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { StateProps } from '@business/interfaces/state';

const initialState: StateProps = {
  id: '',
  countrySelect: ''
};

export const stateStateSlice = createSlice({
  name: 'stateSlice',
  initialState,
  reducers: {
    setStateId: (state, action) => {
      state.id = action.payload.id;
    },
    setCountrySelect: (state, action) => {
      state.countrySelect = action.payload.countrySelect;
    }
  }
});

export const { setStateId, setCountrySelect } = stateStateSlice.actions;
export const getStateId = (state: RootState) => state.stateSlice;
export const getCountrySelect = (state: RootState) =>
  state.stateSlice.countrySelect;
