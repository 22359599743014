import { localareaApi as api } from './localareaApi';
export const addTagTypes = ['region'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      regionDistrictList: build.query<
        RegionDistrictListApiResponse,
        RegionDistrictListApiArg
      >({
        query: queryArg => ({
          url: `/region/district/`,
          params: {
            country__uuid: queryArg.countryUuid,
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['region']
      }),
      regionDistrictCreate: build.mutation<
        RegionDistrictCreateApiResponse,
        RegionDistrictCreateApiArg
      >({
        query: queryArg => ({
          url: `/region/district/`,
          method: 'POST',
          body: queryArg.districtCreate
        }),
        invalidatesTags: ['region']
      }),
      regionDistrictRead: build.query<
        RegionDistrictReadApiResponse,
        RegionDistrictReadApiArg
      >({
        query: queryArg => ({ url: `/region/district/${queryArg.uuid}/` }),
        providesTags: ['region']
      }),
      regionDistrictPartialUpdate: build.mutation<
        RegionDistrictPartialUpdateApiResponse,
        RegionDistrictPartialUpdateApiArg
      >({
        query: queryArg => ({
          url: `/region/district/${queryArg.uuid}/`,
          method: 'PATCH',
          body: queryArg.districtCreate
        }),
        invalidatesTags: ['region']
      }),
      regionDistrictDelete: build.mutation<
        RegionDistrictDeleteApiResponse,
        RegionDistrictDeleteApiArg
      >({
        query: queryArg => ({
          url: `/region/district/${queryArg.uuid}/`,
          method: 'DELETE'
        }),
        invalidatesTags: ['region']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };
export type RegionDistrictListApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: DistrictListRead[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionDistrictListApiArg = {
  /** country__uuid */
  countryUuid?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type RegionDistrictCreateApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: DistrictListRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionDistrictCreateApiArg = {
  districtCreate: DistrictCreate;
};
export type RegionDistrictReadApiResponse = /** status 200 Custom Response */ {
  results: DistrictListRead;
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionDistrictReadApiArg = {
  uuid: string;
};
export type RegionDistrictPartialUpdateApiResponse =
  /** status 200 Custom Response */ {
    results: DistrictListRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionDistrictPartialUpdateApiArg = {
  uuid: string;
  districtCreate: DistrictCreate;
};
export type RegionDistrictDeleteApiResponse =
  /** status 200 Custom Response */ {
    results: DistrictListRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionDistrictDeleteApiArg = {
  uuid: string;
};
export type StateLite = {
  name: string;
  st: string;
};
export type StateLiteRead = {
  uuid?: string;
  name: string;
  st: string;
};
export type Country = {
  name?: string | null;
  co: string;
  ordering: string;
};
export type CountryRead = {
  uuid?: string;
  name?: string | null;
  co: string;
  ordering: string;
};
export type DistrictList = {
  name?: string | null;
  prev_lead?: string | null;
  until?: string | null;
  date_time?: string | null;
  lead_name?: string | null;
  state?: StateLite;
  country?: Country;
};
export type ProfileLiteRegion = {
  profile_pic?: string | null;
  cover_pic?: string | null;
};
export type ProfileLiteRegionRead = {
  uuid?: string;
  profile_pic?: string | null;
  cover_pic?: string | null;
};
export type LocalLeader = {
  first_name?: string;
  last_name?: string;
  profile?: ProfileLiteRegion;
};
export type LocalLeaderRead = {
  uuid?: string;
  first_name?: string;
  last_name?: string;
  profile?: ProfileLiteRegionRead;
};
export type ZipCodesLite = {
  zip: string;
  primary_city?: string | null;
};
export type ZipCodesLiteRead = {
  uuid?: string;
  zip: string;
  primary_city?: string | null;
};
export type DistrictListRead = {
  uuid?: string;
  name?: string | null;
  lead?: LocalLeaderRead[];
  prev_lead?: string | null;
  until?: string | null;
  date_time?: string | null;
  lead_name?: string | null;
  state?: StateLiteRead;
  country?: CountryRead;
  zipcodes?: ZipCodesLiteRead[];
  primary_city?: string[];
  timezone?: string[];
};
export type DistrictCreate = {
  zipcodes?: string[];
  name?: string | null;
  lead?: string[];
  prev_lead?: string | null;
  until?: string | null;
  date_time?: string | null;
  lead_name?: string | null;
  state?: string | null;
  country?: string | null;
};
export const {
  useRegionDistrictListQuery,
  useRegionDistrictCreateMutation,
  useRegionDistrictReadQuery,
  useRegionDistrictPartialUpdateMutation,
  useRegionDistrictDeleteMutation
} = injectedRtkApi;
