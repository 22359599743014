import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { UserUserinfoListApiResponse } from '@ports/internal-services/user/userSlice';

export type authStateType = {
  userData: UserUserinfoListApiResponse;
};

const initialState: authStateType = {
  userData: {} as UserUserinfoListApiResponse
};

export const userStateSlice = createSlice({
  name: 'userStateSlice',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    }
  }
});

export const { setUserData } = userStateSlice.actions;
export const getUserData = (state: RootState) => state.userStateSlice.userData;
