import {
    Button,
    InputField,
    Pagination,
    Table
  } from '@business/workflows/ComponentUI';
  import BaseAppLayout from '../Layout/BaseAppLayout';
  import { useTranslation } from 'react-i18next';
  import { SVGAssets } from '@business/assets/vectors';
  import { useEffect, useMemo, useState } from 'react';
  import { useAccountDetailsDownloadCentreListQuery } from '@ports/internal-services/downloadCenter/downloadCenterSlice';
  import { limit, dateTimeFormatWithSlash } from '@utils/constants/globals';
  import { RegionStateListTemporaryApiArg } from '@ports/internal-services/state/stateSlice';
  import moment from 'moment';
  
  const ExportRequestList = () => {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [search, setSearch] = useState<string>('');
    const [sorting, setSorting] = useState('');
    const [filterOptions, setFilterOptions] =
      useState<RegionStateListTemporaryApiArg>({
        search: '',
        limit: 10,
        offset: 0,
        ordering: sorting
      });
  
    const { data: downloadList, refetch } =
      useAccountDetailsDownloadCentreListQuery({
        ...filterOptions
      });
  
    useEffect(() => {
      refetch();
    }, []);
    useEffect(() => {
      const handler = setTimeout(() => {
        setFilterOptions({ ...filterOptions, search });
      }, 800);
      return () => {
        clearTimeout(handler);
      };
    }, [search]);
  
    useEffect(() => {
      refetch();
    }, [filterOptions, search]);
  
    const handleSort = (column: string) => {
      if (sorting === column) {
        setSorting('');
        setFilterOptions({ ...filterOptions, ordering: '' });
      } else {
        setSorting(column);
        setFilterOptions({ ...filterOptions, ordering: column });
      }
    };
  
    const renderDownloadActionComponent = (rowData: any) => (
      <div className="flex   flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mt-2 md:mt-0 items-center justify-between">
        <span>
          {rowData?.original?.status &&
          rowData?.original?.status == 'downloadable'
            ? t('downloadCenter.completedStatus')
            : t('downloadCenter.inprogressStatus')}
        </span>
        <div className="flex items-center p-2 w-full md:w-auto">
          {rowData?.original?.status &&
          rowData?.original?.status == 'downloadable' ? (
            <a
              className="font-medium th-badge status-tags !mb-0 w-fit md:w-32 text-center whitespace-nowrap"
              data-testid={rowData?.original?.name}
              href={rowData?.original?.url}>
              <span>{t('Download')}</span>
            </a>
          ) : (
            <p className="font-medium th-badge !mb-0 w-fit md:w-32 text-center whitespace-nowrap download-center-inactive-btn">
              {t('Download')}
            </p>
          )}
        </div>
      </div>
    );
  
    const columns = useMemo(() => {
      return [
        {
          Header: () => (
            <Button
              btnStyle="new"
              className="header-cell"
              testid="name"
              onClick={() => handleSort('name')}>
              {`Name ${sorting === 'name' ? ' ↑' : ' ↓'}`}
            </Button>
          ),
          accessor: 'name',
          key: 'name'
        },
        {
          Header: () => (
            <Button
              btnStyle="new"
              className="header-cell"
              testid="created_at"
              onClick={() => handleSort('created_at')}>
              {`Created and Time ${sorting === 'created_at' ? ' ↑' : ' ↓'}`}
            </Button>
          ),
          accessor: 'created_at',
          key: 'created_at',
          Cell: ({ row }: any) =>
            moment(row?.original?.created_at).format(dateTimeFormatWithSlash)
        },
        {
          Header: () => (
            <Button btnStyle="new" className="header-cell" testid="file_size">
              {`File Size`}
            </Button>
          ),
          accessor: 'file_size',
          key: 'file_size'
        },
        {
          Header: () => (
            <Button btnStyle="new" className="header-cell" testid="status">
              {`Status`}
            </Button>
          ),
          accessor: 'status',
          key: 'status',
          Cell: ({ row }: any) => renderDownloadActionComponent(row)
        }
      ];
    }, [sorting]);

    useEffect(() => {
      const newFilterOptions = { ...filterOptions };
      newFilterOptions.offset = (currentPage - 1) * limit;
      setFilterOptions(newFilterOptions);
    }, [currentPage]);
  
    return (
      <BaseAppLayout>
        <div
          data-testid="country-creation"
          className="flex justify-between items-center mb-5">
          <h1 className="color-primary uppercase" data-testid="title">
            {t('downloadCenter.downloadCenterManagement')}
          </h1>
        </div>
        <p className="text-[var(--na-lite-gray4)]">
          {t('downloadCenter.downloadCenterSubTitle')}
        </p>
        <div className="flex mb-3 flex-col md:flex-row justify-between mt-4 w-full items-center">
          <div className="w-full sm:w-full md:w-4/12">
            <div className="flex justify-between items-center">
              <div className="relative w-full">
                <InputField
                  placeholder={t('downloadCenter.search')}
                  className="w-full border input-search border-[var(--na-primary-color-hover)] rounded-full pl-5 pr-10 py-1.5 text-sm  button button-transparent"
                  onChange={e => setSearch(e.target.value)}
                />
                <SVGAssets.SearchIcon
                  className="absolute top-1/2 transform -translate-y-1/2 right-3 w-4 h-4 cursor-pointer text-[var(--na-primary-color-hover-lite)]"
                  data-testid="search-icon"
                />
              </div>
            </div>
          </div>
        </div>
  
        <Table
          tableColumns={columns}
          tableData={downloadList?.results ?? []}
          className="table w-full tw-default-table rounded-md h-10"
          classNameHead="text-white !pt-4 pb-4  w-auto font-medium text-sm bg-[var(--na-primary-color-hover)]"
          classNameBody="text-xs border-zip-table"
          tableBodyClassName="bg-white "
          classNameTd="border-zip-table"
          classNameMain="overflow-x-scroll zip-list"
        />
        <Pagination
          totalCount={downloadList?.count ?? 0}
          currentPage={currentPage}
          pageSize={limit}
          onPageChange={(page: number) => {
            setCurrentPage(page);
          }}
        />
      </BaseAppLayout>
    );
  };
  
  export default ExportRequestList;
  