import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { notificationApi } from '@ports/internal-services/notification/notificationApi';
import { countryApi } from '@ports/internal-services/country/countryApi';
import { fileExportApi } from '@ports/internal-services/fileExport/fileExportApi';
import { authApi } from '../internal-services/auth/authApi';
import { sessionStateSlice } from './sessionStateSlice';
import { alertSlice } from './alertStateSlice';
import { appLoadingStateSlice } from './appLoadingStateSlice';
import { manualLoadingStateSlice } from './manualLoadingStateSlice';
import { countryStateSlice } from './countryDetailsSlice';
import { authStateSlice } from './authStateSlice';
import { stateApi } from '@ports/internal-services/state/stateApi';
import { zipCodeApi } from '@ports/internal-services/zipCodes/zipCodeApi';
import { stateStateSlice } from './stateDetailsSlice';
import { userApi } from '@ports/internal-services/user/userApi';
import { uploadProgressStateSlice } from './uploadProgressStateSlice';
import { userStateSlice } from './userStateSlice';
import { credentialsStateSlice } from './credentialStateSlice';
import { scheduleApi } from '@ports/internal-services/schedule/scheduleApi';
import { localareaApi } from '@ports/internal-services/localarea/localareaApi';
import { localareaDetailsSlice } from './localareaDetailsStateSlice';
import { sideBarStateSlice } from './sideBarStateSlice';
import { faqApi } from '@ports/internal-services/faq/faqApi';
import { resendTimerStateSlice } from './resendTimerStateSlice';
import { zipcodeStateSlice } from './zipcodeStateSlice';
import { downloadCenterApi } from '@ports/internal-services/downloadCenter/downloadCenterApi';
import { mealRequestApi } from '@ports/internal-services/mealRequest/mealRequestApi';
import { resourceApi } from '@ports/internal-services/resource/resourceApi';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-'
};

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [countryApi.reducerPath]: countryApi.reducer,
  [stateApi.reducerPath]: stateApi.reducer,
  [fileExportApi.reducerPath]: fileExportApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [scheduleApi.reducerPath]: scheduleApi.reducer,
  [localareaApi.reducerPath]: localareaApi.reducer,
  [resourceApi.reducerPath]: resourceApi.reducer,
  [sessionStateSlice.name]: sessionStateSlice.reducer,
  [alertSlice.name]: alertSlice.reducer,
  [appLoadingStateSlice.name]: appLoadingStateSlice.reducer,
  [manualLoadingStateSlice.name]: manualLoadingStateSlice.reducer,
  [countryStateSlice.name]: countryStateSlice.reducer,
  [authStateSlice.name]: authStateSlice.reducer,
  [zipCodeApi.reducerPath]: zipCodeApi.reducer,
  [stateStateSlice.name]: stateStateSlice.reducer,
  [uploadProgressStateSlice.name]: uploadProgressStateSlice.reducer,
  [userStateSlice.name]: userStateSlice.reducer,
  [credentialsStateSlice.name]: credentialsStateSlice.reducer,
  [localareaDetailsSlice.name]: localareaDetailsSlice.reducer,
  [sideBarStateSlice.name]: sideBarStateSlice.reducer,
  [faqApi.reducerPath]: faqApi.reducer,
  [mealRequestApi.reducerPath]: mealRequestApi.reducer,
  [resendTimerStateSlice.name]: resendTimerStateSlice.reducer,
  [zipcodeStateSlice.name]: zipcodeStateSlice.reducer,
  [downloadCenterApi.reducerPath]: downloadCenterApi.reducer
});

export { rootPersistConfig, rootReducer };
