import { SVGAssets } from '@business/assets/vectors';
import React from 'react';

interface ZipCodeItemProps {
  zipCodes?: string;
  onClose?: () => void;
  isLocalAreaCard?: boolean;
}

const ZipCodeItem: React.FC<ZipCodeItemProps> = ({
  zipCodes,
  onClose,
  isLocalAreaCard = false
}) => {
  return (
    <div
      className={`w-20 flex items-center ${isLocalAreaCard ? 'justify-center border-primary-color' : 'justify-between'} bg-[var(--na-primary-color-hover)] text-white p-1.5 rounded-full relative`}>
      <span
        className={`text-xs pl-1 overflow-hidden whitespace-nowrap text-ellipsis ${zipCodes && zipCodes.length > 6 ? 'tooltip' : ''}`}
        data-testid="zip-code">
        {zipCodes}
      </span>
      {!isLocalAreaCard && (
        <SVGAssets.CloseButtonRounded
          className="w-4 h-4 cursor-pointer color-primary"
          data-testid="close-button"
          onClick={onClose}
        />
      )}

      {zipCodes && zipCodes.length > 6 && (
        <div className="tooltip-content absolute bg-black text-white text-xs p-1 rounded mt-1 left-1/2 transform -translate-x-1/2">
          {zipCodes}
        </div>
      )}
    </div>
  );
};

export default ZipCodeItem;
