import { countryApi as api } from './countryApi';
export const addTagTypes = ['region', 'user'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      regionCountryList: build.query<
        RegionCountryListApiResponse,
        RegionCountryListApiArg
      >({
        query: queryArg => ({
          url: `/region/country/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['region']
      }),
      regionCountryCreate: build.mutation<
        RegionCountryCreateApiResponse,
        RegionCountryCreateApiArg
      >({
        query: queryArg => ({
          url: `/region/country/`,
          method: 'POST',
          body: queryArg.countryList
        }),
        invalidatesTags: ['region']
      }),
      regionCountryDetailedList: build.query<
        RegionCountryDetailedListApiResponse,
        RegionCountryDetailedListApiArg
      >({
        query: queryArg => ({
          url: `/region/country/detailed/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['region']
      }),
      regionCountryDetailedCreate: build.mutation<
        RegionCountryDetailedCreateApiResponse,
        RegionCountryDetailedCreateApiArg
      >({
        query: queryArg => ({
          url: `/region/country/detailed_create/`,
          method: 'POST',
          body: queryArg.countryDetailed
        }),
        invalidatesTags: ['region']
      }),
      regionCountryRead: build.query<
        RegionCountryReadApiResponse,
        RegionCountryReadApiArg
      >({
        query: queryArg => ({ url: `/region/country/${queryArg.uuid}/` }),
        providesTags: ['region']
      }),
      regionCountryUpdate: build.mutation<
        RegionCountryUpdateApiResponse,
        RegionCountryUpdateApiArg
      >({
        query: queryArg => ({
          url: `/region/country/${queryArg.uuid}/`,
          method: 'PUT',
          body: queryArg.countryList
        }),
        invalidatesTags: ['region']
      }),
      regionCountryDelete: build.mutation<
        RegionCountryDeleteApiResponse,
        RegionCountryDeleteApiArg
      >({
        query: queryArg => ({
          url: `/region/country/${queryArg.uuid}/`,
          method: 'DELETE'
        }),
        invalidatesTags: ['region']
      }),
      regionCountryDetailedInstance: build.query<
        RegionCountryDetailedInstanceApiResponse,
        RegionCountryDetailedInstanceApiArg
      >({
        query: queryArg => ({
          url: `/region/country/${queryArg.uuid}/detailed/`
        }),
        providesTags: ['region']
      }),
      regionCountryDetailedUpdate: build.mutation<
        RegionCountryDetailedUpdateApiResponse,
        RegionCountryDetailedUpdateApiArg
      >({
        query: queryArg => ({
          url: `/region/country/${queryArg.uuid}/detailed_update/`,
          method: 'PATCH',
          body: queryArg.countryDetailed
        }),
        invalidatesTags: ['region']
      }),
      regionCountrySeedList: build.query<
        RegionCountrySeedListApiResponse,
        RegionCountrySeedListApiArg
      >({
        query: queryArg => ({
          url: `/region/country_seed/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['region']
      }),
      regionCountrySeedCreate: build.mutation<
        RegionCountrySeedCreateApiResponse,
        RegionCountrySeedCreateApiArg
      >({
        query: queryArg => ({
          url: `/region/country_seed/`,
          method: 'POST',
          body: queryArg.countrySeed
        }),
        invalidatesTags: ['region']
      }),
      regionTimezoneList: build.query<
        RegionTimezoneListApiResponse,
        RegionTimezoneListApiArg
      >({
        query: queryArg => ({
          url: `/region/timezone/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['region']
      }),
      userUsersList: build.query<UserUsersListApiResponse, UserUsersListApiArg>(
        {
          query: queryArg => ({
            url: `/user/users/`,
            params: {
              username: queryArg.username,
              email: queryArg.email,
              first_name: queryArg.firstName,
              last_name: queryArg.lastName,
              search: queryArg.search,
              ordering: queryArg.ordering,
              limit: queryArg.limit,
              offset: queryArg.offset
            }
          }),
          providesTags: ['user']
        }
      )
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };
export type RegionCountryListApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: CountryListRead[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionCountryListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type RegionCountryCreateApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: CountryListRead[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionCountryCreateApiArg = {
  countryList: CountryList;
};
export type RegionCountryDetailedListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: CountryListRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionCountryDetailedListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type RegionCountryDetailedCreateApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: CountryListRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionCountryDetailedCreateApiArg = {
  countryDetailed: CountryDetailed;
};
export type RegionCountryReadApiResponse = /** status 200 Custom Response */ {
  results: CountryListRead;
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionCountryReadApiArg = {
  uuid: string;
};
export type RegionCountryUpdateApiResponse = /** status 200 Custom Response */ {
  results: CountryListRead;
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionCountryUpdateApiArg = {
  uuid: string;
  countryList: CountryList;
};
export type RegionCountryDeleteApiResponse = /** status 200 Custom Response */ {
  results: CountryListRead;
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionCountryDeleteApiArg = {
  uuid: string;
};
export type RegionCountryDetailedInstanceApiResponse =
  /** status 200 Custom Response */ {
    results: CountryListRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionCountryDetailedInstanceApiArg = {
  uuid: string;
};
export type RegionCountryDetailedUpdateApiResponse =
  /** status 200 Custom Response */ {
    results: CountryListRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionCountryDetailedUpdateApiArg = {
  uuid: string;
  countryDetailed: CountryDetailed;
};
export type RegionCountrySeedListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: CountrySeedRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionCountrySeedListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type RegionCountrySeedCreateApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: CountrySeedRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionCountrySeedCreateApiArg = {
  countrySeed: CountrySeed;
};
export type RegionTimezoneListApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: TimezoneRead[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionTimezoneListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type UserUsersListApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: UserRead[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type UserUsersListApiArg = {
  /** username */
  username?: string;
  /** email */
  email?: string;
  /** first_name */
  firstName?: string;
  /** last_name */
  lastName?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type CountryList = {
  name?: string | null;
  co: string;
  ordering: string;
};
export type ProfileLiteRegion = object;
export type ProfileLiteRegionRead = {
  uuid?: string;
  profile_pic?: string | null;
  cover_pic?: string | null;
};
export type RegionalDirector = {
  first_name?: string;
  last_name?: string;
  profile?: ProfileLiteRegion;
};
export type RegionalDirectorRead = {
  uuid?: string;
  first_name?: string;
  last_name?: string;
  profile?: ProfileLiteRegionRead;
};
export type StateLite = {
  name: string;
  st: string;
};
export type StateLiteRead = {
  uuid?: string;
  name: string;
  st: string;
};
export type Timezone = {
  name?: string | null;
  offset?: string | null;
  country?: string | null;
};
export type TimezoneRead = {
  uuid?: string;
  name?: string | null;
  offset?: string | null;
  country?: string | null;
};
export type CountryListRead = {
  uuid?: string;
  name?: string | null;
  co: string;
  ordering: string;
  director?: RegionalDirectorRead[];
  state?: StateLiteRead[];
  timezone?: TimezoneRead[];
  validation_details?: {
    zipcode?: string;
  };
};
export type CountryDetailed = {
  name?: string | null;
  co?: string;
  ordering?: string;
  director?: string[];
  state?: string[];
  timezone?: string[];
};
export type CountryDetailedRead = {
  uuid?: string;
  name?: string | null;
  co?: string;
  ordering?: string;
  director?: string[];
  state?: string[];
  timezone?: string[];
};
export type CountrySeed = {
  name?: string | null;
  co: string;
};
export type CountrySeedRead = {
  name?: string | null;
  co: string;
  timezone?: TimezoneRead[];
};
export type ProfileList = {
  gender?: 'M' | 'F' | 'O';
  birth_date?: string | null;
  contact_number?: string | null;
  street_address?: string | null;
  city?: string | null;
  state?: string | null;
  country_origin?: string | null;
  zip_code?: string | null;
  profile_pic?: string;
  cover_pic?: string;
  volunteering_with?: boolean;
};
export type ProfileListRead = {
  uuid?: string;
  id?: number;
  gender?: 'M' | 'F' | 'O';
  birth_date?: string | null;
  over_18?: string;
  contact_number?: string | null;
  street_address?: string | null;
  city?: string | null;
  state?: string | null;
  country_origin?: string | null;
  zip_code?: string | null;
  profile_pic?: string;
  cover_pic?: string;
  volunteering_with?: boolean;
};
export type GroupLite = {
  name: string;
};
export type GroupLiteRead = {
  uuid?: string;
  name: string;
};
export type OtherDetailsProfile = {
  cb_source?: string | null;
  cb_othersource?: string | null;
  cb_employer?: string | null;
  cb_organization?: string | null;
  cb_streetaddress?: string | null;
};
export type OtherDetailsProfileRead = {
  uuid?: string;
  cb_source?: string | null;
  cb_othersource?: string | null;
  cb_employer?: string | null;
  cb_organization?: string | null;
  cb_streetaddress?: string | null;
};
export type MamaLiteProfileEdit = {
  cb_mamastart?: string | null;
  cb_mamasmsok?: number | null;
};
export type MamaLiteProfileEditRead = {
  uuid?: string;
  cb_mamastart?: string | null;
  cb_mamasmsok?: number | null;
};
export type User = {
  first_name?: string;
  last_name?: string;
  email: string;
  profile: ProfileList;
  groups?: GroupLite;
  other_details: OtherDetailsProfile;
  mamaprofile: MamaLiteProfileEdit;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
};
export type UserRead = {
  uuid?: string;
  id?: number;
  first_name?: string;
  last_name?: string;
  email: string;
  date_joined?: string;
  profile: ProfileListRead;
  groups?: GroupLiteRead;
  other_details: OtherDetailsProfileRead;
  mamaprofile: MamaLiteProfileEditRead;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
};
export const {
  useRegionCountryListQuery,
  useRegionCountryCreateMutation,
  useRegionCountryDetailedListQuery,
  useRegionCountryDetailedCreateMutation,
  useRegionCountryReadQuery,
  useRegionCountryUpdateMutation,
  useRegionCountryDeleteMutation,
  useRegionCountryDetailedInstanceQuery,
  useRegionCountryDetailedUpdateMutation,
  useRegionCountrySeedListQuery,
  useRegionCountrySeedCreateMutation,
  useRegionTimezoneListQuery,
  useUserUsersListQuery
} = injectedRtkApi;
