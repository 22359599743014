import { fileExportApi as api } from './fileExportApi';
export const addTagTypes = ['import_export'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      importExportExportCsv: build.mutation<Blob, ImportExportExportCsvApiArg>({
        query: queryArg => ({
          url: `/import_export/export_csv/`,
          method: 'POST',
          body: queryArg.export,
          responseHandler: response => response.blob()
        }),
        invalidatesTags: ['import_export']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };
export type ImportExportExportCsvApiArg = {
  export: Export;
};
export type Export = {
  model: string;
  export_format?: 'csv' | 'excel';
  fields: string[];
  filters?: {
    search?: string;
    ordering?: string;
  };
};
export const { useImportExportExportCsvMutation } = injectedRtkApi;
