import { FC } from 'react';
import { AppLoader } from '@business/workflows/ComponentUI/AppLoader';
import { Header } from '@business/workflows/ComponentUI/Header';
import { Sidebar } from '@business/workflows/ComponentUI/Sidebar';
import Breadcrumb, {
  Path
} from '@business/workflows/ComponentUI/BreadCrumbs/BreadCrumbs';

interface BaseAppLayoutProps {
  id?: string;
  mainClass?: string;
  breadcrumbPaths?: Path[];
  baseAppClass?: string;
}

const BaseAppLayout: FC<React.PropsWithChildren<BaseAppLayoutProps>> = ({
  id = '',
  mainClass = '',
  children,
  breadcrumbPaths,
  baseAppClass
}) => {
  const isAdmin = false; /* Need to get from backend */
  return (
    <main className="lasagna-theme th-bg-image relative w-full">
      <div data-testid={id}>
        <div className={mainClass}>
          <Header />
          <section className="th-container content-section h-full pb-10">
            {<Breadcrumb paths={breadcrumbPaths} />}
            <div className="flex flex-wrap justify-between w-full sideBar-profile-class mt-2">
              <Sidebar isAdmin={isAdmin} />
              <div
                className={`w-full lg:w-10/12 mb-2  lg:mb-0 ${baseAppClass ? 'base-layout-height' : ''}`}>
                <div
                  className={
                    baseAppClass || 'bg-white rounded-lg h-full w-full p-4'
                  }>
                  {children}
                </div>
              </div>
            </div>
          </section>
          <div data-testid="app-loader">
            <AppLoader />
          </div>
        </div>
      </div>
    </main>
  );
};
export default BaseAppLayout;
