import { stateApi as api } from './stateApi';
const addTagTypes = ['region'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      regionCountryDetailedCreate: build.mutation<
        RegionCountryDetailedCreateApiResponse,
        RegionCountryDetailedCreateApiArg
      >({
        query: queryArg => ({
          url: `/region/country/detailed_create/`,
          method: 'POST',
          body: queryArg.countryDetailed
        }),
        invalidatesTags: ['region']
      }),
      regionStateList: build.query<
        RegionStateListApiResponse,
        RegionStateListApiArg
      >({
        query: queryArg => ({
          url: `/region/state/`,
          params: {
            country__uuid: queryArg.countryUuid,
            name: queryArg.name,
            st: queryArg.st,
            director_name: queryArg.directorName,
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['region']
      }),
      regionStateCreate: build.mutation<
        RegionStateCreateApiResponse,
        RegionStateCreateApiArg
      >({
        query: queryArg => ({
          url: `/region/state/`,
          method: 'POST',
          body: queryArg.stateDetailed
        }),
        invalidatesTags: ['region']
      }),
      regionStateListTemporary: build.query<
        RegionStateListTemporaryApiResponse,
        RegionStateListTemporaryApiArg
      >({
        query: queryArg => ({
          url: `/region/state/list-uploaded/`,
          params: {
            country__uuid: queryArg.countryUuid,
            name: queryArg.name,
            st: queryArg.st,
            director_name: queryArg.directorName,
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            country_id: queryArg.countryId
          }
        }),
        providesTags: ['region']
      }),
      regionStateUploadFile: build.mutation<
        RegionStateUploadFileApiResponse,
        RegionStateUploadFileApiArg
      >({
        query: queryArg => ({
          url: `/region/state/upload/`,
          method: 'POST',
          body: queryArg.stateFileUpload,
          formData: true
        }),
        invalidatesTags: ['region']
      }),
      regionStateUploadUploadProceed: build.mutation<
        RegionStateUploadUploadProceedApiResponse,
        RegionStateUploadUploadProceedApiArg
      >({
        query: queryArg => ({
          url: `/region/state/upload/proceed/`,
          method: 'POST',
          body: queryArg.stateUploadProceed
        }),
        invalidatesTags: ['region']
      }),
      regionStateRead: build.query<
        RegionStateReadApiResponse,
        RegionStateReadApiArg
      >({
        query: queryArg => ({ url: `/region/state/${queryArg.uuid}/` }),
        providesTags: ['region']
      }),
      regionStateUpdate: build.mutation<
        RegionStateUpdateApiResponse,
        RegionStateUpdateApiArg
      >({
        query: queryArg => ({
          url: `/region/state/${queryArg.uuid}/`,
          method: 'PUT',
          body: queryArg.stateDetailed
        }),
        invalidatesTags: ['region']
      }),
      regionStateDelete: build.mutation<
        RegionStateDeleteApiResponse,
        RegionStateDeleteApiArg
      >({
        query: queryArg => ({
          url: `/region/state/${queryArg.uuid}/`,
          method: 'DELETE'
        }),
        invalidatesTags: ['region']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };

export type RegionCountryDetailedCreateApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: CountryListRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionCountryDetailedCreateApiArg = {
  countryDetailed: CountryDetailed;
};
export type RegionStateListApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: StateRead[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionStateListApiArg = {
  /** country__uuid */
  countryUuid?: string;
  /** name */
  name?: string;
  /** st */
  st?: string;
  /** director_name */
  directorName?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type RegionStateCreateApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: StateRead[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionStateCreateApiArg = {
  stateDetailed: StateDetailed;
};
export type RegionStateListTemporaryApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: StateRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionStateListTemporaryApiArg = {
  /** country__uuid */
  countryUuid?: string;
  /** name */
  name?: string;
  /** st */
  st?: string;
  /** director_name */
  directorName?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Country ID */
  countryId?: number;
};
export type RegionStateUploadFileApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: StateRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionStateUploadFileApiArg = {
  stateFileUpload: FormData;
};
export type RegionStateUploadUploadProceedApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: StateRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type RegionStateUploadUploadProceedApiArg = {
  stateUploadProceed: StateUploadProceed;
};
export type RegionStateReadApiResponse = /** status 200 Custom Response */ {
  results: StateRead;
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionStateReadApiArg = {
  uuid: string;
};
export type RegionStateUpdateApiResponse = /** status 200 Custom Response */ {
  results: StateRead;
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionStateUpdateApiArg = {
  uuid: string;
  stateDetailed: StateDetailed;
};
export type RegionStateDeleteApiResponse = /** status 200 Custom Response */ {
  results: StateRead;
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionStateDeleteApiArg = {
  uuid: string;
};
export type CountryList = {
  name?: string | null;
  co: string;
  ordering: string;
};
export type ProfileLiteRegion = object;
export type ProfileLiteRegionRead = {
  uuid?: string;
  profile_pic?: string | null;
  cover_pic?: string | null;
};
export type RegionalDirector = {
  first_name?: string;
  last_name?: string;
  profile?: ProfileLiteRegion;
};
export type RegionalDirectorRead = {
  uuid?: string;
  first_name?: string;
  last_name?: string;
  profile?: ProfileLiteRegionRead;
};
export type StateLite = {
  name: string;
  st: string;
};
export type StateLiteRead = {
  uuid?: string;
  name: string;
  st: string;
};
export type Timezone = {
  name?: string | null;
  offset?: string | null;
  country?: string | null;
};
export type TimezoneRead = {
  uuid?: string;
  name?: string | null;
  offset?: string | null;
  country?: string | null;
};
export type CountryListRead = {
  uuid?: string;
  name?: string | null;
  co: string;
  ordering: string;
  director?: RegionalDirectorRead[];
  state?: StateLiteRead[];
  timezone?: TimezoneRead[];
};
export type CountryDetailed = {
  name?: string | null;
  co?: string;
  ordering?: string;
  director?: string[];
  state?: string[];
  timezone?: string[];
};
export type CountryDetailedRead = {
  uuid?: string;
  name?: string | null;
  co?: string;
  ordering?: string;
  director?: string[];
  state?: string[];
  timezone?: string[];
};
export type State = {
  name: string;
  st: string;
  ordering: number;
  optimize?: string | null;
  map?: string | null;
  director_name?: string | null;
  country?: string | null;
};
export type ZipCodesLite = {
  zip: string;
  primary_city?: string | null;
};
export type ZipCodesLiteRead = {
  uuid?: string;
  zip: string;
  primary_city?: string | null;
};
export type StateRead = {
  uuid?: string;
  name: string;
  st: string;
  directors?: RegionalDirectorRead[];
  ordering: number;
  optimize?: string | null;
  map?: string | null;
  director_name?: string | null;
  country?: string | null;
  zipcodes?: ZipCodesLiteRead[];
  timezone?: TimezoneRead[];
};
export type StateDetailed = {
  name: string;
  st: string;
  directors?: string[];
  ordering: number;
  optimize?: string | null;
  map?: string | null;
  director_name?: string | null;
  country?: string | null;
  zipcodes?: string[];
};
export type StateFileUpload = {
  country_uuid: string;
};
export type StateFileUploadRead = {
  file?: string;
  country_uuid: string;
};
export type StateUploadProceed = {
  country_uuid: string;
};
export const {
  useRegionCountryDetailedCreateMutation,
  useRegionStateListQuery,
  useRegionStateCreateMutation,
  useRegionStateListTemporaryQuery,
  useRegionStateUploadFileMutation,
  useRegionStateUploadUploadProceedMutation,
  useRegionStateReadQuery,
  useRegionStateUpdateMutation,
  useRegionStateDeleteMutation
} = injectedRtkApi;
