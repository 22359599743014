import React from 'react';
import { useTranslation } from 'react-i18next';
import { SVGAssets } from '@business/assets/vectors';
import { LeaderCardItem } from './LeaderCardItem';

export interface Leader {
  uuid: string;
  name: string;
  first_name: string;
  last_name: string;
  imageUrl: string;
}

interface LocalLeaderCardProps {
  selectedLocalLeaders?: string[];
  onRemoveLocalLeader?: (id: string) => void;
  assignedLabel?: string;
  isCountryDetail?: boolean;
  displayItems?: any;
}

export const LocalLeaderCard: React.FC<LocalLeaderCardProps> = ({
  selectedLocalLeaders,
  onRemoveLocalLeader,
  assignedLabel,
  isCountryDetail,
  displayItems
}) => {
  const { t } = useTranslation();
  const displayedLeaders = displayItems?.results?.length ? displayItems?.results?.filter((item: any) =>
    selectedLocalLeaders?.includes(item.uuid)
  ) : [];

  return (
    <>
      <div
        className={`flex items-center ${!isCountryDetail ? 'p-2' : 'px-3 py-2'}`}>
        <SVGAssets.MatchesIcon
          className="w-5 h-5 text-[var(--na-primary-color-active)] rounded-full flex items-center justify-center border-primary-color p-1"
          data-testid="matches-icon"
        />

        <h4 className="text-[var(--na-primary-color-active)] font-semibold ml-3 !text-sm">
          {assignedLabel ?? t('area.assigned')}
        </h4>
      </div>
      <hr className="w-full border-[var(--na-primary-color-hover)] border-t mt-1" />
      <div className="flex flex-wrap mt-4 custom-scrollbar common-scrollbar overflow-y-auto md:!h-[200px]">
        {displayedLeaders?.map((leader: any) => (
          <LeaderCardItem
            key={leader.uuid}
            leader={leader}
            onRemove={onRemoveLocalLeader}
            isCountryDetail={isCountryDetail}
          />
        ))}
      </div>
    </>
  );
};
