import { scheduleApi as api } from './scheduleApi';
export const addTagTypes = ['user'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      userProfileScheduleRead: build.query<
        UserProfileScheduleReadApiResponse,
        UserProfileScheduleReadApiArg
      >({
        query: queryArg => ({
          url: `/user/profile_schedule/${queryArg.uuid}/`
        }),
        providesTags: ['user']
      }),
      userProfileSchedulePartialUpdate: build.mutation<
        UserProfileSchedulePartialUpdateApiResponse,
        UserProfileSchedulePartialUpdateApiArg
      >({
        query: queryArg => ({
          url: `/user/profile_schedule/${queryArg.uuid}/`,
          method: 'PATCH',
          body: queryArg.profileSchedule
        }),
        invalidatesTags: ['user']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };
export type UserProfileScheduleReadApiResponse =
  /** status 200 Custom Response */ {
    results: ProfileScheduleRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserProfileScheduleReadApiArg = {
  uuid: string;
};
export type UserProfileSchedulePartialUpdateApiResponse =
  /** status 200 Custom Response */ {
    results: ProfileScheduleRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserProfileSchedulePartialUpdateApiArg = {
  uuid: string;
  profileSchedule: ProfileSchedule;
};
export type MamaLiteProfile = {
  cb_pausedate?: string | null;
  cb_mamafreq?: string | null;
  cb_mamacap?: number | null;
  cb_mamaradius?: number | null;
  cb_mamaradiustype?: string | null;
  cb_mamaavail?: ('Active' | 'Paused') | null;
};
export type Schedule = {
  cb_schedule?: string | null;
  cb_week1cap?: number | null;
  cb_week2cap?: number | null;
  cb_week3cap?: number | null;
  cb_week4cap?: number | null;
  cb_week5cap?: number | null;
  cb_week6cap?: number | null;
  cb_week7cap?: number | null;
  cb_week8cap?: number | null;
  cb_week1avail?: number | null;
  cb_week2avail?: number | null;
  cb_week3avail?: number | null;
  cb_week4avail?: number | null;
  cb_week5avail?: number | null;
  cb_week6avail?: number | null;
  cb_week8avail?: number | null;
  cb_week7avail?: number | null;
};
export type ProfileSchedule = {
  mamaprofile: MamaLiteProfile;
  schedule: Schedule;
};
export type ProfileScheduleRead = {
  uuid?: string;
  mamaprofile: MamaLiteProfile;
  schedule: Schedule;
};
export const {
  useUserProfileScheduleReadQuery,
  useUserProfileSchedulePartialUpdateMutation
} = injectedRtkApi;
