import { faqApi as api } from './faqApi';
export const addTagTypes = ['content_manager'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      contentManagerFaqsList: build.query<
        ContentManagerFaqsListApiResponse,
        ContentManagerFaqsListApiArg
      >({
        query: queryArg => ({
          url: `/content_manager/faqs/`,
          params: {
            group_access: queryArg.groupAccess,
            group_access__name: queryArg.groupAccessName,
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['content_manager']
      }),
      contentManagerFaqsCreate: build.mutation<
        ContentManagerFaqsCreateApiResponse,
        ContentManagerFaqsCreateApiArg
      >({
        query: queryArg => ({
          url: `/content_manager/faqs/`,
          method: 'POST',
          body: queryArg.faq
        }),
        invalidatesTags: ['content_manager']
      }),
      contentManagerFaqsRead: build.query<
        ContentManagerFaqsReadApiResponse,
        ContentManagerFaqsReadApiArg
      >({
        query: queryArg => ({ url: `/content_manager/faqs/${queryArg.uuid}/` }),
        providesTags: ['content_manager']
      }),
      contentManagerFaqsPartialUpdate: build.mutation<
        ContentManagerFaqsPartialUpdateApiResponse,
        ContentManagerFaqsPartialUpdateApiArg
      >({
        query: queryArg => ({
          url: `/content_manager/faqs/${queryArg.uuid}/`,
          method: 'PATCH',
          body: queryArg.faq
        }),
        invalidatesTags: ['content_manager']
      }),
      contentManagerFaqsDelete: build.mutation<
        ContentManagerFaqsDeleteApiResponse,
        ContentManagerFaqsDeleteApiArg
      >({
        query: queryArg => ({
          url: `/content_manager/faqs/${queryArg.uuid}/`,
          method: 'DELETE'
        }),
        invalidatesTags: ['content_manager']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };
export type ContentManagerFaqsListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: FaqRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type ContentManagerFaqsListApiArg = {
  /** group_access */
  groupAccess?: string;
  /** group_access__name */
  groupAccessName?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type ContentManagerFaqsCreateApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: FaqRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type ContentManagerFaqsCreateApiArg = {
  faq: Faq;
};
export type ContentManagerFaqsReadApiResponse =
  /** status 200 Custom Response */ {
    results: FaqRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type ContentManagerFaqsReadApiArg = {
  uuid: string;
};
export type ContentManagerFaqsPartialUpdateApiResponse =
  /** status 200 Custom Response */ {
    results: FaqRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type ContentManagerFaqsPartialUpdateApiArg = {
  uuid: string;
  faq: Faq;
};
export type ContentManagerFaqsDeleteApiResponse =
  /** status 200 Custom Response */ {
    results: FaqRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type ContentManagerFaqsDeleteApiArg = {
  uuid: string;
};
export type Faq = {
  question: string;
  answer: string;
  order?: number;
  group_access?: number | null;
};
export type FaqRead = {
  uuid?: string;
  question: string;
  answer: string;
  order?: number;
  created_at?: string;
  group_access?: number | null;
};
export const {
  useContentManagerFaqsListQuery,
  useContentManagerFaqsCreateMutation,
  useContentManagerFaqsReadQuery,
  useContentManagerFaqsPartialUpdateMutation,
  useContentManagerFaqsDeleteMutation
} = injectedRtkApi;
