import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

const initialState: {
  AWS_ACCESS_KEY_ID: string;
  AWS_SECRET_ACCESS_KEY: string;
} = {
  AWS_ACCESS_KEY_ID: '',
  AWS_SECRET_ACCESS_KEY: ''
};

export const credentialsStateSlice = createSlice({
  name: 'credentialsStateSlice',
  initialState,
  reducers: {
    setCredential: (state, action) => {
      return {
        AWS_ACCESS_KEY_ID: action.payload.AWS_ACCESS_KEY_ID,
        AWS_SECRET_ACCESS_KEY: action.payload.AWS_SECRET_ACCESS_KEY
      };
    }
  }
});

export const { setCredential } = credentialsStateSlice.actions;

export const getCredential = (state: RootState) => state.credentialsStateSlice;
