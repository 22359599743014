import React, { Dispatch, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SVGAssets } from '@business/assets/vectors';
import { Alert, InputField, Tabs } from '@business/workflows/ComponentUI';
import { AlertProps } from '@business/interfaces/alert';
import { Images } from '@business/assets/images';
import { RegionZipcodesListApiResponse } from '@ports/internal-services/zipCodes/zipCodeSlice';
import { AppMiniLoader } from '@business/workflows/ComponentUI/AppMiniLoader';
interface AreaCardProps {
  onZipCodeSelection?: (zipCode: string, isSelected: boolean) => void;
  onLocalLeaderSelection?: (id: string, isSelected: boolean) => void;
  selectedLocalLeaders?: string[];
  selectedZipCodes?: string[];
  showLocalTabOnly?: boolean;
  label?: string;
  placeholder?: string;
  displayItems?: any;
  searchQuery?: string;
  setSearchQuery?: (value: any) => void;
  lastUserElementRef?: any;
  lastZipCodeElementRef?: any;
  isUsersLoading?: boolean;
  isZipCodesLoading?: boolean;
  zipCodeList?: RegionZipcodesListApiResponse;
  zipCodeSearchQuery?: string;
  setZipCodeSearchQuery?: Dispatch<string>;
  isLocalArea?: boolean;
  onZipCodeRemoval?: (zipCode: string) => void;
  setSelectAllZip?: (value: boolean) => void;
  selectAllZip?: boolean;
  setSelectAllClick?: (value: boolean) => void;
  selectAllClick?: boolean;
}

export const AreaCard: React.FC<AreaCardProps> = ({
  onZipCodeSelection,
  onLocalLeaderSelection,
  selectedLocalLeaders,
  selectedZipCodes,
  showLocalTabOnly,
  label,
  placeholder,
  displayItems,
  searchQuery,
  setSearchQuery,
  lastUserElementRef,
  lastZipCodeElementRef,
  isUsersLoading,
  isZipCodesLoading,
  zipCodeList,
  zipCodeSearchQuery,
  setZipCodeSearchQuery,
  isLocalArea,
  onZipCodeRemoval,
  setSelectAllZip,
  selectAllZip,
  setSelectAllClick,
  selectAllClick
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string>('zip');

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertData, setAlertData] = useState<AlertProps | null>(null);

  const searchBoxClass =
    'w-full p-2 border border-[var(--na-lite-gray3)] rounded-lg shadow-sm focus:outline-none';

  const checkboxClass =
    'p-2 w-full custom-scrollbar common-scrollbar overflow-y-auto md:!h-[382px]';

  const tabItems = [
    {
      label: t('area.zip'),
      value: 'zip',
      icon: (
        <SVGAssets.ZipIcon
          className={`w-6 h-6 rounded-full flex items-center justify-center border-primary-color p-1 ${
            selectedTab === 'zip'
              ? 'bg-[var(--na-primary-color)] text-white'
              : 'bg-white color-primary'
          }`}
          data-testid="matches-icon"
        />
      )
    },
    {
      label: t('area.local'),
      value: 'local',
      icon: (
        <SVGAssets.MatchesIcon
          className={`w-5 h-5 rounded-full flex items-center justify-center border-primary-color p-1 ${
            selectedTab === 'local'
              ? 'bg-[var(--na-primary-color)] text-white'
              : 'bg-white color-primary'
          }`}
          data-testid="location-icon"
        />
      )
    }
  ];

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
    setShowAlert(false);
  };
  const handleCheckboxChange = (zipCode: string, isChecked: boolean) => {
    const zipCodeDetails = zipCodeList?.results.find(
      zip => zip.zip === zipCode
    );
    if (zipCodeDetails?.district && isChecked && isLocalArea) {
      onZipCodeSelection(zipCode, isChecked);
      setShowAlert(true);
      setAlertData({
        type: 'warning',
        heading: t('area.warning-msg')
      });
    } else if (zipCodeDetails?.district && !isChecked && isLocalArea) {
      onZipCodeRemoval(zipCode);
      setShowAlert(false);
      setAlertData(null);
    } else if (isChecked) {
      onZipCodeSelection(zipCode, isChecked);
    } else if (!isChecked) {
      onZipCodeRemoval(zipCode);
    } else {
      setShowAlert(false);
    }
  };

  const handleLocalCheckboxChange = (id: string, isChecked: boolean) => {
    onLocalLeaderSelection(id, isChecked);
  };

  return (
    <>
      {!showLocalTabOnly ? (
        <>
          <div className="tabs flex flex-wrap mb-5 mt-4 p-0 area-card">
            {tabItems.map(tabItem => (
              <Tabs
                key={tabItem.value}
                label={tabItem.label}
                isActive={selectedTab === tabItem.value}
                onClick={() => handleTabChange(tabItem.value)}
                icon={tabItem?.icon}
                labelClassName="!text-[--var(na-primary-color-hover)] !text-sm justify-start"
                isFullWidth
              />
            ))}
          </div>
          {showAlert && (
            <div className="flex relative mb-4" data-testid="alert">
              <Alert
                onClose={() => {
                  setShowAlert(false);
                  setAlertData(null);
                }}
                {...alertData}
                wrapperClass="w-full"
              />
            </div>
          )}
          {selectedTab === 'zip' && (
            <>
              <div className="p-3 pt-0 md:flex gap-3 items-center justify-between">
                <InputField
                  type="text"
                  name="zip-search"
                  value={zipCodeSearchQuery}
                  onChange={e => setZipCodeSearchQuery(e.target.value)}
                  placeholder={t('area.searchZip')}
                  data-testid="area"
                  className={searchBoxClass}
                  inputWrapperClassName={`${isLocalArea && zipCodeSearchQuery ? 'lg:w-9/12 md:w-4/5 w-full' : 'w-full'}`}
                />
                {isLocalArea && zipCodeSearchQuery && (
                  <>
                    <div className="flex gap-1 items-center md:mt-0 mt-4 !text-[var(--na-primary-color)] ">
                      <input
                        type="checkbox"
                        data-testid="selectall-checkbox"
                        name={'selectAll'}
                        className="th-checkbox primary xl justify-center items-center flex"
                        checked={Boolean(selectAllZip && selectAllClick)}
                        onChange={() => {
                          setSelectAllZip(!selectAllZip);
                          setSelectAllClick(!selectAllClick);
                        }}
                      />
                      <label htmlFor="selectAll" className="text-sm">
                        {t('localArea.selectAll')}
                      </label>
                    </div>
                  </>
                )}
              </div>
              {zipCodeList?.results?.length > 0 ? (
                <div className="p-1 w-full custom-scrollbar common-scrollbar overflow-y-auto md:!h-[382px]">
                  {zipCodeList?.results.map((zip, index) => (
                    <div
                      key={zip.zip}
                      className="flex items-center p-2 mb-2"
                      ref={
                        index === zipCodeList?.results?.length - 1
                          ? lastZipCodeElementRef
                          : null
                      }>
                      <input
                        type="checkbox"
                        name={zip?.zip}
                        id={`checkbox-${zip?.zip}`}
                        data-testid={`checkbox-${zip?.zip}`}
                        className="th-checkbox primary xl !mr-2"
                        checked={selectedZipCodes?.includes(zip.zip)}
                        onChange={e =>
                          handleCheckboxChange(zip?.zip, e.target.checked)
                        }
                      />
                      <div className="flex-1 text-sm">
                        <div className="flex items-center">
                          <span className="font-semibold mr-1">{zip?.zip}</span>
                          ,
                          <span className="font-semibold">
                            {zip?.primary_city}
                          </span>
                          {zip?.district && isLocalArea && (
                            <SVGAssets.TikMark className="w-4 h-4 ml-2 color-primary" />
                          )}
                        </div>
                        {zip?.district && isLocalArea && (
                          <div className="text-xs color-primary">
                            {t('district.id')} - {zip?.district_name}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {isZipCodesLoading && <AppMiniLoader />}
                </div>
              ) : (
                <div className="text-sm font-medium text-[--na-secondary-color] flex justify-center">
                  {t('noResults')}
                </div>
              )}
            </>
          )}
          {selectedTab === 'local' && (
            <>
              <div className="p-3 pt-0">
                <InputField
                  type="text"
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                  placeholder={t('area.placeholder')}
                  data-testid="area"
                  className={searchBoxClass}
                />
              </div>
              {displayItems?.length > 0 ? (
                <div className={checkboxClass}>
                  {displayItems?.map((item: any, index: number) => (
                    <div
                      key={item.id}
                      className="flex items-center mb-3"
                      ref={
                        index === displayItems?.length - 1
                          ? lastUserElementRef
                          : null
                      }>
                      <input
                        type="checkbox"
                        id={`checkbox-${item.uuid}`}
                        name={item?.name}
                        data-testid={`local-checkbox-${item.uuid}`}
                        className="th-checkbox primary xl !mr-2"
                        checked={selectedLocalLeaders?.includes(item?.uuid)}
                        onChange={e =>
                          handleLocalCheckboxChange(
                            item?.uuid,
                            e.target.checked
                          )
                        }
                      />
                      <img
                        src={item.imageUrl ?? Images.ProfileImage}
                        height={100}
                        width={88}
                        className="w-12 h-12 rounded-full border border-gray-300 mr-3"
                      />
                      <span className="text-sm font-medium text-[var(--na-secondary-color-hover)]">
                        {item.first_name} {item?.last_name}
                      </span>
                    </div>
                  ))}
                  {isUsersLoading && <AppMiniLoader />}
                </div>
              ) : (
                <div className="text-sm font-medium text-[--na-secondary-color] flex justify-center">
                  {t('noResults')}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <div className="flex items-center p-2">
            <SVGAssets.UserIcon
              className="w-6 h-6 text-white rounded-full flex items-center justify-center border-primary-color bg-[var(--na-primary-color)] p-1"
              data-testid="location-icon"
            />

            <h4 className="text-[var(--na-primary-color-active)] font-semibold ml-3">
              {label}
            </h4>
          </div>
          <hr className="w-full border-[var(--na-primary-color-hover)] border-t mt-1 mb-4" />
          <div className="p-3 pt-0">
            <InputField
              type="text"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              placeholder={placeholder}
              data-testid="area"
              className="w-full p-2 border border-[var(--na-lite-gray3)] text-[var(--na-lite-gray3)] rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-primary text-xs"
            />
          </div>
          {displayItems?.results?.length > 0 ? (
            <div className={checkboxClass}>
              {displayItems?.results?.map((item: any, index: number) => (
                <div
                  key={item.id}
                  className="flex items-center mb-3"
                  ref={
                    index === displayItems?.results?.length - 1
                      ? lastUserElementRef
                      : null
                  }>
                  <input
                    type="checkbox"
                    id={`checkbox-${item.uuid}`}
                    name={item?.name}
                    data-testid={`local-checkbox-${item.uuid}`}
                    className="th-checkbox primary xl !mr-2"
                    checked={selectedLocalLeaders?.includes(item?.uuid)}
                    onChange={e =>
                      handleLocalCheckboxChange(item.uuid, e.target.checked)
                    }
                  />
                  <img
                    src={item.imageUrl ?? Images.ProfileImage}
                    height={100}
                    width={88}
                    className="w-12 h-12 rounded-full border border-gray-300 mr-3"
                  />
                  <span className="text-sm font-medium text-[var(--na-secondary-color-hover)]">
                    {item.first_name} {item?.last_name}
                  </span>
                </div>
              ))}
              {isUsersLoading && <AppMiniLoader />}
            </div>
          ) : (
            <div className="text-sm font-medium text-[--na-secondary-color] flex justify-center">
              {t('noResults')}
            </div>
          )}
        </>
      )}
    </>
  );
};
