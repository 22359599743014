import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';

import { authApi } from '@ports/internal-services/auth/authApi';
import { notificationApi } from '@ports/internal-services/notification/notificationApi';
import { countryApi } from '@ports/internal-services/country/countryApi';
import { fileExportApi } from '@ports/internal-services/fileExport/fileExportApi';
import { rootReducer, rootPersistConfig } from './reducer';
import { stateApi } from '@ports/internal-services/state/stateApi';
import { zipCodeApi } from '@ports/internal-services/zipCodes/zipCodeApi';
import { userApi } from '@ports/internal-services/user/userApi';
import { scheduleApi } from '@ports/internal-services/schedule/scheduleApi';
import { localareaApi } from '@ports/internal-services/localarea/localareaApi';
import { faqApi } from '@ports/internal-services/faq/faqApi';
import { downloadCenterApi } from '@ports/internal-services/downloadCenter/downloadCenterApi';
import { mealRequestApi } from '@ports/internal-services/mealRequest/mealRequestApi';
import { resourceApi } from '@ports/internal-services/resource/resourceApi';

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      },
      immutableCheck: false
    })
      .concat(authApi.middleware)
      .concat(notificationApi.middleware)
      .concat(countryApi.middleware)
      .concat(stateApi.middleware)
      .concat(fileExportApi.middleware)
      .concat(zipCodeApi.middleware)
      .concat(userApi.middleware)
      .concat(scheduleApi.middleware)
      .concat(localareaApi.middleware)
      .concat(faqApi.middleware)
      .concat(downloadCenterApi.middleware)
      .concat(mealRequestApi.middleware)
      .concat(resourceApi.middleware)
});

export const persister = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
