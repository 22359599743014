import { useSelector } from 'react-redux';
import { getAppLoadingState } from '@ports/common/appLoadingStateSlice';
import {
  getManualLoadingState,
  getOpacityRequired
} from '@ports/common/manualLoadingStateSlice';
import { Images } from '@business/assets/images';

export const AppLoader = () => {
  const isAppLoading = useSelector(getAppLoadingState);
  const isManualLoading = useSelector(getManualLoadingState);
  const isOpacityRequired = useSelector(getOpacityRequired);
  return (
    <>
      {(isAppLoading || isManualLoading) && (
        <div
          data-testid="loader"
          className={`z-[99999] fixed top-0 left-0 w-screen h-screen flex align-center justify-center select-none overflow-none ${isOpacityRequired ? 'bg-white' : 'bg-white/75'} `}>
          <img
            src={Images.Loader}
            height={200}
            width={200}
            alt="loadingIcon"
            className="object-contain"
          />
        </div>
      )}
    </>
  );
};
